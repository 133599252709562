import classNames from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { renderContent } from '../../utils';

function BlogHeroCard({ post, index }) {
  const image = post.featuredImage.node.localFile;

  return (
    <div key={index} className="blog-hero-card">
      <GatsbyImage
        className={classNames('img-fluid blog-hero-card-image')}
        image={getImage(image)}
        alt={post.title}
        style={{
          width: '100%',
          height: '75dvh',
          aspectRatio: '16 / 9',
        }}
      />
      <div className="blog-hero-card-content">
        <h2 className="text-headline mb-2 mb-md-3">
          <Link to={post.slug}>{post.title}</Link>
        </h2>
        <p className="text-small" dangerouslySetInnerHTML={renderContent(post.excerpt)} />
      </div>
    </div>
  );
}

export default BlogHeroCard;
