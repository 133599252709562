import React, { useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import CommonCard from './common-card';
import { createBlogLink } from '../../utils';

function PostsSlider({ title, posts, seeMoreLink, seeMoreText, infinite }) {
  const swipperRef = useRef(null);

  const data = useStaticQuery(graphql`
    query PostSliderFallbackImage {
      fallbackImage: file(name: { regex: "/statue-liberty/" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const swiperSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: posts.edges.length >= 3 ? 3 : posts.edges.length,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
      <div className="container">
        <h2 className="text-mega text-grey mb-5">{title}</h2>
        <div className="position-relative">
          <Slider className="swiper-container-2" {...swiperSettings} ref={swipperRef}>
            {posts.edges.map((edge) => {
              const { title, date, slug, excerpt, featuredImage, id } = edge.node;
              return (
                <div className="col-12 mb-6 mb-lg-0" key={id}>
                  <CommonCard
                    className=""
                    key={id}
                    title={title}
                    date={date}
                    imgSrc={featuredImage?.node?.localFile || data.fallbackImage}
                    link={createBlogLink(slug)}
                    description={excerpt}
                  />
                </div>
              );
            })}
          </Slider>
          <div
            className="swiper-button-prev-unique"
            onClick={() => {
              swipperRef.current.slickPrev();
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </div>
          <div
            className="swiper-button-next-unique"
            onClick={() => {
              swipperRef.current.slickNext();
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
        {seeMoreText && seeMoreLink && (
          <div className="text-right mt-4">
            <Link to={seeMoreLink}>
              <span>{seeMoreText}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-arrow-right pl-3 mb-3"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

PostsSlider.propTypes = {};

export default PostsSlider;
