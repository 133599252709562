import React, { useRef } from 'react';
import Slider from 'react-slick';
import BlogHeroCard from './blog-hero-card';

const swiperSettings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  delay: 10000,
  waitForAnimate: false,
};

function BlogHeroSlider({ postData }) {
  const swiperRef = useRef(null);

  return (
    <div className="section pb-5">
      <div className="blog-hero-slider">
        <div className="position-relative">
          <Slider className="" {...swiperSettings} ref={swiperRef}>
            {postData.map((post, index) => (
              <BlogHeroCard key={index} index={index} post={post.node} />
            ))}
          </Slider>
          <div
            className="hero-slider-button-prev d-none d-md-block"
            onClick={() => {
              swiperRef.current.slickPrev();
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-chevron-left d-block position-relative"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </div>
          <div
            className="hero-slider-button-next d-none d-md-block"
            onClick={() => {
              swiperRef.current.slickNext();
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogHeroSlider;
